body,
html,
#root,
.App {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.table-container table {
  width: 100%;
  table-layout: fixed;
}

input {
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}
